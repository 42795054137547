<template>
    <v-layout>
        <v-flex xs12>
            <v-card class="wrap-content gray-icons">
                <v-toolbar color="transparent">
                    <v-toolbar-title class="light-blue--text title-block">
                        Resellers
                    </v-toolbar-title>
                    <v-spacer />
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search Agencies"
                        single-line
                        hide-details
                        :loading="loadingAgencies" />
                    <v-spacer />
                    <template v-if="loadingAgencies">
                        <v-progress-circular
                            indeterminate
                            :width="6"
                            color="white" />
                    </template>
                    <template v-if="!loadingAgencies">
                        <v-tooltip
                            v-model="shouldRefreshAgencies"
                            top>
                            <v-btn
                                slot="activator"
                                icon
                                @click="refreshAgencies()">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                            <span>Refresh Agencies</span>
                        </v-tooltip>
                    </template>
                    <v-tooltip
                        v-model="addAgency"
                        top>
                        <v-btn
                            slot="activator"
                            icon
                            to="agency/new/">
                            <v-icon>add</v-icon>
                        </v-btn>
                        <span>Add Agency</span>
                    </v-tooltip>
                </v-toolbar>
                <v-data-table
                    :headers="headers"
                    :items="agencies"
                    :total-items="pagination.totalItems"
                    :pagination.sync="pagination"
                    :loading="loadingAgencies"
                    hide-actions
                    class="pa-4">
                    <template
                        slot="items"
                        slot-scope="props">
                        <router-link
                            tag="tr"
                            :to="/agencies/+props.item.id">
                            <td class="text-xs-right">
                                {{ props.item.id }}
                            </td>
                            <td class="text-xs-left">
                                {{ props.item.name }}
                            </td>
                            <td class="text-xs-left">
                                {{ props.item.url }}
                                <v-btn
                                    v-if="props.item.url"
                                    flat
                                    fab
                                    small
                                    color="primary"
                                    :href="props.item.url"
                                    target="_blank"
                                    @click.native="_blank">
                                    <v-icon>link</v-icon>
                                </v-btn>
                            </td>
                            <td class="text-xs-left">
                                {{ props.item.leads_from_email }}
                            </td>
                        </router-link>
                    </template>
                    <template slot="progress">
                        <loader />
                    </template>
                </v-data-table>
                <div class="text-xs-center dealers-onboarding-pagination py-4">
                    <template v-if="raw.meta">
                        <v-pagination
                            v-model="pagination.page"
                            :length="raw.meta.last_page"
                            prev-icon="mdi-menu-left"
                            next-icon="mdi-menu-right"
                            :total-visible="5"
                            @input="onPagination" />
                    </template>
                </div>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import Loader from '../globals/Loader.vue';

export default {
    components: {
        Loader
    },
    title: 'Agencies',
    data() {
        return {
            raw: [],
            search: '',
            pagination: {
                sortBy: 'name',
                descending: true,
                rowsPerPage: 25,
                totalItems: 0
            },
            headers: [
                { text: 'ID', value: 'id', align: 'right' },
                { text: 'Name', value: 'name', align: 'left' },
                { text: 'Website URL', value: 'url', align: 'left'  },
                { text: 'Leads From Email', value: 'leads_from_email' }
            ],
            agencies: [],
            errors: [],
            loadingAgencies: true,
            addAgency: false,
            typingTimer: null,
            shouldRefreshAgencies: false,
        };
    },
    watch: {
        pagination() {
            this.getAgencies();
        },
        search() {
            if(this.typingTimer) clearTimeout(this.typingTimer);

            this.typingTimer = setTimeout(() => {
                this.getAgencies();
            }, 2000);
        },
    },
    created() {

    },
    methods: {
        getAgencies() {
            this.loadingAgencies = true;
            this.agencies = [];

            this.$apiRepository
                .getAgencies(this.pagination, this.search)
                .then(response => {
                    this.agencies = response.data.data;
                    this.raw = response.data;
                    this.pagination.totalItems = this.raw.meta.total;
                    this.loadingAgencies = false;
                })
                .catch(e => {
                    this.loadingAgencies = false;
                    console.log(e);
                });
        },
        onPagination() {
            this.getAgencies();
        },
        refreshAgencies() {
            this.getAgencies();
        }
    }
};
</script>

<style>
    .btn.btn--floating:not(.btn--absolute):not(.btn--fixed) { z-index: 0 }
</style>
